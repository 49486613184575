<template>
  <CRow class="get_started">
    <CCol :cols="videoColumns.cols" :xl="videoColumns.xl" :lg="videoColumns.lg" :md="videoColumns.md" :sm="videoColumns.sm">
      <youtube v-if="showVideo" :video-id="youtubeId" ref="youtube" :player-vars="playerVars" :fitParent="true"></youtube>
    </CCol>
    <CCol :cols="contentColumns.cols" :xl="contentColumns.xl" :lg="contentColumns.lg" :md="contentColumns.md" :sm="contentColumns.sm">
      <div class="d-flex content-center flex-column h-100">
        <i class="fas fa-box-open"></i>
        <p v-html="content"></p>
      </div>
    </CCol>    
  </CRow>
</template>

<script>

export default {
  name: 'getStarted',
  props: ['content', 'showVideo', 'youtubeId'],
  data() {
    return {
      videoColumns: { cols: 6, xl: 6, lg: 6, md: 12, sm: 12 },
      contentColumns: { cols: 6, xl: 6, lg: 6, md: 12, sm: 12 },
      playerVars: { 
        autoplay: 0, 
        controls: 0,
        playsinline: 0,
        loop: 0,
        fs: 0,
        disablekb: 1,
        start: 8
      }
    }
  },
  methods: {
    playVideo() {
      this.$refs.youtube.player.playVideo();
    },
    pauseVideo() {
      this.$refs.youtube.player.pauseVideo();
    }
  },
  mounted: function() {
    if(this.showVideo === false) {
      this.videoColumns = { cols: 0, xl: 0, lg: 0, md: 0, sm: 0 };
      this.contentColumns = { cols: 12, xl: 12, lg: 12, md: 12, sm: 12 };
    }
  }
}
</script>

<style lang="scss">
.get_started iframe {
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  overflow: hidden;
  z-index: 1;
  height: 400px;
  width: 480px;
}

.get_started p {
  font-size: 1.2em;
  line-height: 30px;
}

.get_started i {
  font-size: 3em;
  margin-bottom: 10px;
}
</style>