<template>
  <CRow v-if="platformRolesLoaded && platformPermissionsLoaded && checkPermission('core.targetgroups.overview')">
    <CCol cols="12" lg="12" md="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
              {{ $t("groups.Target_groups") }}
            </CCol>
            <CCol cols="7" xl="7" class="pt-0 pb-0">              
              <div class="d-flex align-items-center justify-content-xl-end">
                <b-switch class="m-0" v-model="groupFilters.show_inactive" size="is-small" @input="filterGroups();">{{ $t('common.Show_inactive') }}</b-switch>
                <CButton v-if="checkPermission('core.targetgroups.add')" class="ml-2 mr-0" color="primary" @click="openSidebarRight('target_group', { group_id_external: null })">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{ $t('groups.Create_target_group') }}</span>
                </CButton>
              </div> 
            </CCol> 
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">          
          <div class="target_group_filters">
            <CRow class="m-0">
              <CCol cols="4" lg="4" class="pt-0 pb-0 search">
                <label><b>{{$t('groups.Search_for_target_group')}}</b></label>
                <CInput type="text" class="mb-0 searchbar" v-model="groupFilters.search_term" v-debounce:1s="filterGroups"></CInput>
              </CCol>
            </CRow>
          </div>
          <hr class="mt-2_5" v-bind:class="{'mb-2_5' : groups.length > 0, 'mb-0' : groups.length == 0}">
          <div class="data_table_container">
            <CRow v-if="groupsLoading === true">
              <CCol cols="12" lg="12">
                <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
              </CCol>
            </CRow>
            <CRow v-else>
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <div v-if="groups.length > 0">
                  <b-table class="data_table"
                           ref="groupTable"                           
                           :data="groups"
                           :striped="true"
                           :hoverable="true"
                           :mobile-cards="true"
                           :current-page.sync="currentPage"
                           backend-pagination
                           :paginated="isPaginated"
                           :total="totalItems"
                           :per-page="perPage"
                           :pagination-simple="isPaginationSimple"
                           :pagination-position="paginationPosition"
                           @page-change="onPageChange"
                           backend-sorting
                           :default-sort="[sortField, sortOrder]"
                           :default-sort-direction="defaultSortDirection"
                           :sort-icon="sortIcon"
                           :sort-icon-size="sortIconSize"
                           @sort="onTableSort">
                    
                    <template slot-scope="props">
                      <b-table-column field="title" :label="$t('groups.Title')" :searchable="false" :sortable="true">
                        <span>{{props.row.title}}</span>
                        <div v-if="props.row.description" class="mt-1 meta">
                          <span>{{props.row.description}}</span>
                        </div>
                      </b-table-column>
                      <b-table-column field="total_group_members" :label="$t('groups.Users_included')" width="15%" :sortable="true">
                        <span v-if="props.row.total_group_members !== null">{{props.row.total_group_members}} {{ props.row.total_group_members == 1 ? $t('common.user') : $t('common.users') }} </span>
                        <span v-else><i class="fas fa-hourglass-half mr-1"></i>{{$t('common.Caching_data')}}</span>
                      </b-table-column>                  
                      <b-table-column field="total_filters" :label="$t('groups.Filters_used')" width="15%" :sortable="true">
                        <span v-if="props.row.total_filters !== null">{{props.row.total_filters}} {{ props.row.total_filters == 1 ? $t('common.filter') : $t('common.filters') }}</span>
                        <span v-else><i class="fas fa-hourglass-half mr-1"></i>{{$t('common.Caching_data')}}</span>
                      </b-table-column>
                      <b-table-column field="actions" width="10%" :visible="checkPermission('core.targetgroups.add')">
                        <div class="d-flex justify-content-lg-end">
                          <b-switch v-if="checkRole('super_admin') || props.row.editable === 'Y'" class="mb-0 mr-1" v-model="props.row.is_active" size="is-small" @input="updateGroup(props.row.group_id_external, props.row);">{{ $t('is_active') }}</b-switch>
                          <CDropdown color="primary" stoggler-text="" :caret="false" class="ml-2 table_actions_dropdown">
                            <div slot="toggler-content">
                              <span class="d-flex align-items-center">
                                {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                              </span>
                            </div>

                            <CDropdownItem v-if="checkRole('super_admin') || props.row.editable === 'Y'">
                              <div class="d-flex align-items-center" @click="openSidebarRight('target_group', { group_id_external: props.row.group_id_external });">
                                <div class="post_action_icon text-center mr-1">
                                  <i class="fas fa-pen"/>
                                </div>
                                <div class="flex-grow-1">
                                  <span>{{$t('groups.Edit_target_group')}}</span>
                                </div>
                              </div>
                            </CDropdownItem>
                            <CDropdownItem>
                              <div class="d-flex align-items-center" @click="openSidebarRight('target_group_content', { group_id_external: props.row.group_id_external });">
                                <div class="post_action_icon text-center mr-1">
                                  <i class="fas fa-chevron-right"/>
                                </div>
                                <div class="flex-grow-1">
                                  <span>{{$t('groups.Show_accessible_content')}}</span>
                                </div>
                              </div>
                            </CDropdownItem>
                          </CDropdown>
                        </div>
                      </b-table-column>                                      
                    </template>>                
                  </b-table>
                </div>
                <div v-else class="text-center p-2_5">
                  <span v-if="groupFilters.search_term">{{$t('common.No_employees_based_on_filters')}}</span>
                  <span v-else>{{$t('common.no_groups_found')}}</span>
                </div>                  
              </CCol>
            </CRow>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import getStarted from '@/components/common/getStarted.vue';

export default {
  name: 'Groups',
  components: {
    loadingSpinner,
    noPermission,
    getStarted
  },
  data() {
    return {
      platformRoles: [],
      platformRolesLoaded: false,      
      platformPermissions: [],
      platformPermissionsLoaded: false,
      groups: [],
      groupsLoading: false,
      groupsLoaded: false,
      groupFilters: {
        search_term: '',
        show_inactive: false    
      },
      sortField: 'title',
      sortOrder: 'asc',
      defaultSortDirection: 'asc',           
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: false,
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      totalItems: 0,
      targetGroupsStartIndex: 0      
    }
  },
  methods: {
    getGroups() {
      // Start the loader
      if(this.groupsLoaded === false) this.groupsLoading = true;
      // Set the params
      let params = {};
      params.groupFilters = this.groupFilters;
      params.groupFilters.sort_field = this.sortField;
      params.groupFilters.sort_order = this.sortOrder;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/overview/' + this.targetGroupsStartIndex, params)
      .then(res => {
        // Clear the groups array
        this.groups = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_target_groups;
        // Update the currentTotal value
        if(currentTotal / this.perPage > 1000) currentTotal = this.perPage * 1000;
        // Update the totalItems value
        this.totalItems = currentTotal;
        // Add the target_groups to the groups array
        this.groups = res.data.data.target_groups;
        // Check if the pagination should be activated
        (currentTotal > this.perPage) ? this.isPaginated = true : this.isPaginated = false;        
        // Stop the loader
        this.groupsLoading = false;
        // Update the groupsLoaded value
        this.groupsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateGroup(id, data) {
      let groupIdExternal = id;
      let params = {}
      params = data;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';
      
      axios.put(process.env.VUE_APP_API_URL + '/v1/core/targetgroup/' + groupIdExternal, params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('groups.Target_group_updated'), type: 'is-success', duration: 2000 });
        this.getGroupDetails(groupIdExternal);
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })         
    },
    getGroupDetails(groupIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroup/' + groupIdExternal)
      .then(res => {
        let updatedGroup = res.data.data;
        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.groupTable !== undefined ) {
          // Get the group index
          let groupIndex = this.$refs.groupTable.data.findIndex(x => x.group_id_external == updatedGroup.group_id_external);
          // Update the is active value for the row
          this.$refs.groupTable.data[groupIndex].title = updatedGroup.title;
          this.$refs.groupTable.data[groupIndex].description = updatedGroup.description;
          this.$refs.groupTable.data[groupIndex].total_group_members = updatedGroup.group_members;
          this.$refs.groupTable.data[groupIndex].total_filters = updatedGroup.filters_used;
          this.$refs.groupTable.data[groupIndex].is_active = updatedGroup.is_active;
        }    
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    onPageChange(page) {
      // Set the targetGroupsStartIndex value
      (page > 1) ? this.targetGroupsStartIndex = (page - 1)*10 : this.targetGroupsStartIndex = 0;
      // Get the rewards
      this.getGroups();
    },
    onTableSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      // Reset the pagination;
      this.resetPagination();
      // Get the groups
      this.getGroups()
    },
    filterGroups() {
      // Reset the pagination;
      this.resetPagination();
      // Get the groups
      this.getGroups();
    },
    resetPagination() {
      this.currentPage = 1;
      this.targetGroupsStartIndex = 0;
    },
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
        // Update the platformRolesLoaded value
        this.platformRolesLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    },    
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }
  },
  mounted: function() {
    this.getPlatformRoles();
    this.getPlatformPermissions();
    this.getGroups();

    this.$bus.$on('update_groups', () => {
      this.getGroups();
    }); 

    this.$bus.$on('update_group_details', (userIdExternal) => {
      this.getGroupDetails(userIdExternal);
    });    
  },
  beforeDestroy() {
    this.$bus.$off('update_groups');
    this.$bus.$off('update_group_details');
  }
}
</script>